import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isEmpty } from "lodash";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../components/layout/footer/Footer";
import { getAnEntity } from "../../../services/entities.service";
import Navbar from "../../../components/layout/navbar/Navbar";
import UserCoupleStory from "../../../components/layout/user-couple-story/UserCoupleStory";
import EntityGallery from "../entity-gallery/EntityGallery";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl, getKeyByValue } from "../../../utils/utils";
import EntityUserLiveUpdates from "../../../components/layout/entity-user-live-updates/EntityUserLiveUpdates";
import UserEntityLocation from "../../../components/layout/user-entity-location/UserEntitylocation";
import UserTestimonial from "../../../components/layout/user-testimonial/UserTestimonial";
import AddInfoPopup from "../../../components/layout/add-info/AddInfo";
import { clearFetchEntityAdStates } from "../../../redux/entity/entity.action";
import { clearFetchGalleryEmStates } from "../../../redux/gallery/gallery.action";
import { clearFetchGalleryFoldersEmStates } from "../../../redux/gallery-folders/galleryFolders.action";
import { clearFetchKnowTheHostSectionsEmStates } from "../../../redux/know-the-host/knowTheHost.action";
import { clearFetchLiveFeedsEmStates } from "../../../redux/live-feed/liveFeed.action";
import { clearFetchPermalinkAdStates } from "../../../redux/permalink/permalink.action";
import { clearFetchentityProfileAdState } from "../../../redux/entity-location/entity.location.action";
import { clearFetchTestimonialAdStates } from "../../../redux/testimonials/testimonial.action";
import { clearFetchEventPermalinkEmStates } from "../../../redux/event-permalink/eventPermalink.action";
import OpenGraphMeta from "../../../components/layout/open-graph/OpenGraphMeta";
import { Helmet } from "react-helmet";

const TAB_NAME_INDEX_MAPPING = {
  home: 0,
  "know-the-entity": 1,
  "photo-gallery": 2,
  "live-updates": 3,
  testimonial: 4,
  contactUs: 5,
};

const StyledTabs = styled(Tabs)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
  backgroundColor: "var(--secondary-color)",
  "& .MuiTabs-indicator": {
    backgroundColor: "var(--high-emphasis)",
    height: "2.5px",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: 400,
  fontFamily: '"Poppins" , sans-serif',
  lineHeight: "32px",
  fontSize: "18px",
  color: "var(--medium-emphasis)",
  marginRight: theme.spacing(1),
  // '&:hover': {
  //     color: 'var(--high-emphasis)',
  //     fontWeight: 600,
  // },
  "&.Mui-selected": {
    color: "var(--high-emphasis)",
    fontWeight: 600,
  },
  // '&.Mui-focusVisible': {
  //     backgroundColor: '#d1eaff',
  // },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
    value: index,
  };
}

export default function Entity(props) {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const [loading, setLoading] = useState(true);

  const entityState = useSelector((state) => state.entityAd);

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const queryParams = qs.parse(location.search);
  const selected = queryParams.selected;
  const entityId = props.entityId ? props.entityId : params?.entityId;
  const [addInfoPopup, setAddInfoPopup] = useState(false);

  const entityInfo = entityState.response?.data;
  const settings = entityState.response?.data?.settings
    ? JSON.parse(entityState.response?.data?.settings)
    : {};

  useEffect(() => {
    if (isEmpty(entityState.response)) {
      dispatch(getAnEntity(entityId));
    }
    return () => {
      dispatch(clearFetchEntityAdStates());
      dispatch(clearFetchentityProfileAdState());
      dispatch(clearFetchGalleryEmStates());
      dispatch(clearFetchGalleryFoldersEmStates());
      dispatch(clearFetchKnowTheHostSectionsEmStates());
      dispatch(clearFetchTestimonialAdStates());
      dispatch(clearFetchLiveFeedsEmStates());
      dispatch(clearFetchPermalinkAdStates());
      dispatch(clearFetchEventPermalinkEmStates());
    };
  }, []);

  useEffect(() => {
    if (!entityState.loading && !isEmpty(entityState.response)) {
      setLoading(false);
    }

    if (!entityState.loading && !isEmpty(entityState.error)) {
      setLoading(false);
      toast.error(entityState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [entityState]);

  const handleChange = (event, newValue) => {
    let path;
    if (props.entityName) {
      path = `${props.entityName}`;
    } else {
      path = `entity/${entityId}`;
    }
    if (newValue == 5) {
      //Pop-up for contact us
      setAddInfoPopup(true);
      history.push(`/${path}?selected=home`);
    } else {
      newValue != 0 && setAddInfoPopup(false);
      history.push(
        `/${path}?selected=${getKeyByValue(TAB_NAME_INDEX_MAPPING, newValue)}`
      );
    }
    if (window.pageYOffset) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  function checkFolderSettings(folderId) {
    switch (folderId) {
      case 0:
        return true;
      case 1:
        return settings.hasOwnProperty("know_the_host")
          ? Boolean(settings.know_the_host)
          : true;
      case 2:
        return settings.hasOwnProperty("gallery")
          ? Boolean(settings.gallery)
          : true;
      case 3:
        return settings.hasOwnProperty("live_updates")
          ? Boolean(settings.live_updates)
          : true;
      case 4:
        return settings.hasOwnProperty("testimonial")
          ? Boolean(settings.testimonial)
          : true;
      case 5:
        return settings.hasOwnProperty("contactUs")
          ? Boolean(settings.contactUs)
          : false;
      default:
        return true;
    }
  }

  console.log("entityInfo", window.location.href);
  console.log("Image", entityInfo?.logo_image?.bucket_path);

  const logo = "%PUBLIC_URL%/logo192.png";

  return (
    <>
      {props.entityName && (
        <Helmet>
          <title>{entityInfo?.name ? entityInfo?.name : "Picsea"}</title>
          <meta
            property="og:title"
            content={entityInfo?.name ? entityInfo?.name : "Picsea"}
          />
          <meta property="og:description" content={entityInfo?.entity_title} />
          {entityInfo?.logo_image?.bucket_path ? (
            <meta
              property="og:image"
              content={getImageUrl(entityInfo.logo_image.bucket_path)}
            />
          ) : (
            <meta property="og:image" content={logo} />
          )}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
        </Helmet>
      )}
      <Navbar eventTitle={entityInfo?.name} />
      {selected === "photo-gallery" ? (
        <EntityGallery
          entityId={entityId}
          onBackBtnClick={() => history.goBack()}
        />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              position: "sticky",
              zIndex: 10,
              ...(isSmallScreen ? { top: "56px" } : { top: "64px" }),
            }}
          >
            {!loading ? (
              <StyledTabs
                value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
                onChange={handleChange}
                aria-label="tabs"
                {...(isDesktop
                  ? {
                      centered: true,
                    }
                  : {
                      variant: "scrollable",
                      scrollButtons: "auto",
                      allowScrollButtonsMobile: true,
                    })}
              >
                {checkFolderSettings(0) ? (
                  <StyledTab label="Home" {...a11yProps(0)} />
                ) : null}
                {checkFolderSettings(1) ? (
                  <StyledTab label="About" {...a11yProps(1)} />
                ) : null}
                {checkFolderSettings(2) ? (
                  <StyledTab label="Gallery" {...a11yProps(2)} />
                ) : null}
                {checkFolderSettings(3) ? (
                  <StyledTab label="Updates" {...a11yProps(3)} />
                ) : null}
                {checkFolderSettings(4) ? (
                  <StyledTab label="Testimonials" {...a11yProps(4)} />
                ) : null}
                {checkFolderSettings(5) ? (
                  <StyledTab label="Contact Us  " {...a11yProps(5)} />
                ) : null}
              </StyledTabs>
            ) : null}
          </Box>
          {checkFolderSettings(0) ? (
            <TabPanel
              value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
              index={0}
            >
              <UserEntityLocation
                entityId={entityId}
                entityInfoLoading={loading}
              />
            </TabPanel>
          ) : null}
          {checkFolderSettings(1) ? (
            <TabPanel
              value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
              index={1}
            >
              <UserCoupleStory entityId={entityId} component="entity" />
            </TabPanel>
          ) : null}
          {checkFolderSettings(2) ? (
            <TabPanel
              value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
              index={2}
            >
              {/* <PhotoGallery /> */}
            </TabPanel>
          ) : null}
          {checkFolderSettings(3) ? (
            <TabPanel
              value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
              index={3}
            >
              <EntityUserLiveUpdates entityId={entityId} />
            </TabPanel>
          ) : null}
          {checkFolderSettings(4) ? (
            <TabPanel
              value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
              index={4}
            >
              <UserTestimonial entityId={entityId} />
            </TabPanel>
          ) : null}
        </Box>
      )}
      <Footer />
      {addInfoPopup && (
        <AddInfoPopup
          entityId={entityId}
          handleClose={() => setAddInfoPopup(false)}
        />
      )}
    </>
  );
}
